import { seoProperties } from '@/helpers/seo';
import SplashProvider from '@/providers/splash';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import '../baseline/styles.scss';

const App = dynamic( () => import( './_app.component' ), { ssr: false } );

function _App( props: any ) {
	const seoData = seoProperties[ props.router.pathname ];
	return (
		<SplashProvider>
			<NextSeo
				title={seoData?.title ?? 'Invoiss, Free cloud-based accounting software'}
				defaultTitle='Invoiss, Free cloud-based accounting software'
				description={seoData?.description ?? undefined}
			/>
			<App {...props}/>
		</SplashProvider>
	);
}

export default _App;
